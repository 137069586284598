<template>
    <div>
        <div class="login-form login-signin">
            <div class="text-center mb-10 mb-lg-20">
                <h3 class="font-size-h1">Cambio Password</h3>
                <p class="text-muted font-weight-semi-bold">
                    Per assistenza tecnica scrivere a <a
                        href="mailto:supporto-tecnico@acsi.it">supporto-tecnico@acsi.it</a>.<br>
                    Un nostro tecnico sarà a vostra disposizione.
                </p>
            </div>

            <div v-if="verified === true">
                <div role="alert" class="alert alert-success">
                    <div class="alert-text">
                        Il tuo indirizzo e-mail è stato verificato con successo
                    </div>
                </div>

                <!--begin::Form-->
                <b-form v-if="!form_sent" class="form" @submit.stop.prevent="onSubmit">
                    <div role="alert" class="alert alert-primary">
                        <div class="alert-text">
                            Immetti la tua <strong>Nuova Password</strong> e <strong>Confermala</strong> per il recupero
                            delle tue credenziali.
                        </div>
                    </div>

                    <b-form-group id="password-group" label="" label-for="password">
                        <label for="password">Password *:</label>
                        <b-form-input class="form-control form-control-solid h-auto py-5 px-6" type="password"
                            id="password" name="password" v-model="$v.form.password.$model"
                            :state="validateState('password')" aria-describedby="password-live-feedback"
                            placeholder="Inserisci la nuova password"></b-form-input>

                        <b-form-invalid-feedback id="password-live-feedback">
                            <template v-if="typeof form_errors['password'] !== 'undefined'">
                                <div v-for="error in form_errors['password']">{{ error }}</div>
                            </template>
                            <span v-else>La password è un campo obbligatorio.</span>
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <b-form-group id="password-verify-group" label="" label-for="password-verify">
                        <label for="password">Conferma Password *:</label>
                        <b-form-input class="form-control form-control-solid h-auto py-5 px-6" type="password"
                            id="password-verify" name="password_verify" v-model="$v.form.password_verify.$model"
                            :state="validateState('password_verify')" aria-describedby="password-verify-live-feedback"
                            placeholder="Inserisci la password di conferma"></b-form-input>

                        <b-form-invalid-feedback id="password-verify-live-feedback">
                            <template v-if="typeof form_errors['password_verify'] !== 'undefined'">
                                <div v-for="error in form_errors['password_verify']">{{ error }}</div>
                            </template>
                            <span v-else>La password di conferma è oggligatoria.</span>
                        </b-form-invalid-feedback>
                    </b-form-group>

                    <!--begin::Action-->
                    <div class="form-group d-flex flex-wrap flex-center">
                        <button type="submit" ref="kt_change_submit"
                            class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4">
                            Invia
                        </button>
                        <button type="button" v-on:click.prevent="$router.push('login')"
                            class="btn btn-light-primary font-weight-bold px-9 py-4 my-3 font-size-3 mx-4">
                            Annulla
                        </button>
                    </div>
                    <!--end::Action-->

                    <div class="form-group d-flex flex-wrap justify-content-center align-items-center">
                        <span class="font-weight-bold font-size-3 text-dark-60">
                            Torna alla pagina di
                        </span>
                        <router-link custom class="font-weight-bold font-size-3 ml-2" :to="{ name: 'login' }">
                            Login
                        </router-link>
                    </div>
                </b-form>
                <!--end::Form-->

                <div v-if="form_sent">
                    <div role="alert" class="alert alert-success">
                        <div class="alert-text">
                            La procedura di recupero password è avvenuata con successo.<br>
                            Ora potrai utilizzare la tua nuova password per autenticarti.
                        </div>
                    </div>

                    <button type="button" v-on:click.prevent="$router.push('/login')"
                        class="btn btn-primary btn-block font-weight-bold font-size-3">
                        Torna alla pagina di Login
                    </button>
                </div>
            </div>

            <div v-if="verified === false">
                <div role="alert" class="alert alert-danger">
                    <div class="alert-text">
                        {{ verified_error_message }}
                    </div>
                </div>

                <button type="button" v-on:click.prevent="$router.push('/login')"
                    class="btn btn-light-primary btn-block font-weight-bold font-size-3">
                    Torna alla pagina di Login
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
    padding-right: 3.5rem !important;
}
</style>

<script>
import ApiService from "@/core/services/api.service";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";

export default {
    mixins: [validationMixin],
    name: "verify",
    data() {
        return {
            token: '',

            verified: null,
            verified_error_message: '',

            form_sent: false,

            form_errors: {},

            form: {
                password: "",
                password_verify: ""
            },
        };
    },
    validations: {
        form: {
            password: {
                required
            },
            password_verify: {
                required
            },
        }
    },
    created() {
        this.token = this.$route.params.token;

        let url = '/auth/change-password/' + encodeURI(this.token);
        ApiService.query(url)
            .then((response) => {
                if (response.data.status === 'OK') {
                    this.verified = true;
                    this.verified_error_message = response.data.verified_error_message;
                }

                if (response.data.status === 'KO') {
                    this.verified = false;
                    this.verified_error_message = response.data.verified_error_message;
                }
            })
            .catch((error) => {
                console.log(error);
            })
    },
    methods: {
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name];
            return $dirty ? (typeof this.form_errors[name] !== "undefined" ? false : ($error ? false : null)) : null;
        },
        resetForm() {
            this.form = {
                password: null,
                password_verify: null,
            };

            this.$nextTick(() => {
                this.$v.$reset();
            });
        },
        onSubmit() {
            this.$v.form.$touch();
            if (this.$v.form.$anyError) {
                return;
            }

            const password = this.$v.form.password.$model;
            const password_verify = this.$v.form.password_verify.$model;

            // set spinner to submit button
            const submitButton = this.$refs["kt_change_submit"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            this.form_errors = {};

            let url = '/auth/change-password';
            let data = { token: this.token, password: password, password_verify: password_verify };

            ApiService.post(url, data)
                .then((response) => {
                    if (response.data.status === 'OK') {
                        this.form_sent = true;
                    }

                    if (response.data.status === 'KO') {
                        this.form_errors = JSON.parse(JSON.stringify(response.data.errors));
                    }
                })
                .catch(({ response }) => {
                    if (response.status === 422) {
                        this.form_errors = JSON.parse(JSON.stringify(response.data.errors));
                    }
                    else {
                        console.log(response);
                    }
                });

            submitButton.classList.remove(
                "spinner",
                "spinner-light",
                "spinner-right"
            );
        }
    },
};
</script>
